<template>
  <div class="theme-market-container">
    <div class="flex-align fixed-menu">
      <div class="flex-align drag-scroll-mobile">
        <div v-for="(item, idx) in tabs" :key="'section-item-'+idx"
             :id="`fixed-item-${idx}`"
             class="item-fixed-menu subtitle7"
             :class="`${currentTab !== item.id ? 'sub': 'selected'}`"
             @click="clickTab(item)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <themes v-if="currentTab === 1"></themes>
    <skins v-else-if="currentTab === 2"></skins>
    <templates v-else-if="currentTab === 3"></templates>
    <inquiry v-else-if="currentTab === 4"></inquiry>
  </div>
</template>

<script>
  import Themes from "./Market/Mobile/Themes";
  import Skins from "./Market/Mobile/Skins";
  import Templates from "./Market/Mobile/Templates";
  import Inquiry from "./Market/Mobile/Inquiry";

  export default {
    name: "ThemeMarketMobile",
    components: {Inquiry, Templates, Skins, Themes},
    created() {
      if (this.$route.query.tab) {
        this.currentTab = Number(this.$route.query.tab);
      }

    },
    data() {
      return {
        currentTab: 1,
        tabs: [
          {id: 1, name: '플랫폼파이 테마'},
          {id: 2, name: '플랫폼파이 스킨'},
          {id: 3, name: '웹/앱 템플릿'},
          {id: 4, name: '템플릿 등록문의'}
        ]
      }
    },
    watch: {
      '$route'() {
        if (this.$route.query.tab) {
          this.currentTab = Number(this.$route.query.tab);
        }
      },
      currentTab(n) {
        n--;
        let tabMenu = document.getElementsByClassName('drag-scroll-mobile')[0];

        tabMenu.scrollLeft = document.getElementById(`fixed-item-${n}`).offsetLeft;
      }
    },
    methods: {
      clickTab(item) {
        this.currentTab = item.id;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .theme-market-container
    margin 80px 0

  .tabs-wrapper
    border-bottom 1px solid $gray2
    background-color white

  .tabs
    display grid
    grid-template-columns repeat(4, 1fr)

  .tab
    padding 16px
    text-align center
    border-bottom 2px solid transparent
    color $sub2

  .tab-selected
    color $main
    border-bottom 2px solid $main

  .fixed-menu
    width 100%
    overflow hidden
    position fixed
    top 60px
    left 0
    z-index 9
    background-color white
    border-bottom 1px solid $gray1

  .item-fixed-menu
    padding 12px 10px
    border-bottom 3px solid transparent
    scroll-snap-align start
    color $sub

  .item-fixed-menu:first-child
    margin-left 16px

  .item-fixed-menu:last-child
    margin-right 16px

  .item-fixed-menu.selected
    color black
    font-weight 500
    border-bottom 3px solid black

  .drag-scroll-mobile
    white-space nowrap
    overflow-x scroll
    overflow-y hidden
    scroll-snap-type x mandatory

  .drag-scroll-mobile::-webkit-scrollbar
    display none
</style>
