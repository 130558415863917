<template>
  <div>
    <!-- 인풋 -->
    <div
      class="lp-text-area flex-align
                full-width
                position-relative
                textarea-container"
      :style="{ borderRadius: sendMode ? '8px 8px 0 0' : '8px' }"
    >
      <textarea
        class="textarea"
        :style="{
          minHeight: (minHeight !== undefined) ? minHeight : (sendMode ? '100px' : '120px'),
          resize: resize === true ? 'vertical' : 'none',
        }"
        :class="{ 'error-st': errorMsg }"
        :placeholder="placeholder"
        :maxlength="maxLength"
        @keyup="(e) => onKeyup(e.target.value)"
        @keydown.ctrl.enter.prevent="(e) => onCtrlEnter(e.target.value)"
        v-model="inputValue"
      ></textarea>
      <div class="textarea-length">
        <span>{{ inputValue.length }} / {{ maxLength }}</span>
      </div>
    </div>
    <div v-if="desc" class="desc">{{ desc }}</div>
  </div>
</template>

<script>
export default {
  name: 'LpTextArea',
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    maxLength: {
      type: Number,
      required: 20000,
    },
    placeholder: {
      type: String,
      default: '',
    },
    errorMsg: {
      type: String,
      required: false,
      default: '',
    },
    validate: {
      type: Object,
      default: function() {
        return {
          valid_required: false,
          error_msg: '',
          max_length: 100,
          min_length: 0,
          alpha_s: true,
          alpha_g: true,
          special: true,
          num: true,
        };
      },
    },
    rows: {
      type: Number,
      required: false,
      default: 7,
    },
    desc: {
      type: String,
      required: false,
      default: '',
    },
    sendMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    resize: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: String,
      required: false,
    },
  },

  created() {
    if (this.value) {
      this.inputValue = this.value;
    }
    if (this.validate) {
      this.valid = this.validate;
    }
  },
  watch: {
    value(n) {
      this.inputValue = n;
    },
  },
  data() {
    return {
      inputValue: '',
    };
  },

  computed: {},

  methods: {
    onKeyup(value) {
      this.$emit('update:errorMsg', '');
      this.inputValue = value;
      this.$emit('update:value', this.inputValue);
    },
    onCtrlEnter(value) {
      this.$emit('update:errorMsg', '');
      this.inputValue = value;
      this.$emit('update:value', this.inputValue);
      this.$emit('ctrl-enter', this.inputValue);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.desc
  font-size 14px
  line-height 22px
  color $sub2
  margin-top 8px
  white-space pre-wrap
  text-align left
.error-st
  border 1px solid $error !important
.textarea-container
  position relative
  border 1px solid $gray
  border-radius 8px
  &:focus-within
    border 1px solid $primary

.textarea
  border 0
  padding 12px 16px
  margin-bottom 32px
  &:focus
    border 0
    outline 0


.textarea-length
  position absolute
  right 24px
  bottom 12px
  font-size 12px
  color $sub4
</style>
