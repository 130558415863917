import { render, staticRenderFns } from "./ThemeMarketPc.vue?vue&type=template&id=35a21764&scoped=true"
import script from "./ThemeMarketPc.vue?vue&type=script&lang=js"
export * from "./ThemeMarketPc.vue?vue&type=script&lang=js"
import style0 from "./ThemeMarketPc.vue?vue&type=style&index=0&id=35a21764&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35a21764",
  null
  
)

export default component.exports