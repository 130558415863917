<template>
  <div>
    <div class="banner">
      <div class="container">
        <div class="circle"></div>
        <div style="z-index:1">
          <div class="subtitle6 info">소스 스토어</div>
          <div class="h6 main margin-top-16">
            대표님, 예산을 들여 제작했던 결과물을 운영하지 않고 묵혀두고 계신가요?<br />
            예비창업팀에게 공유하고 수익창출 하세요
          </div>
          <div class="body2 sub2 margin-top-20">
            프리랜서 혹은 인하우스 디자이너, 개발자를 위한 소스 스토어입니다.<br />
            기제작된 결과물을 기반으로 시작하니 빠르고 효율적으로 제작할 수 있습니다.<br />
            창업팀이 디자인을 구매해서 플랫폼파이로 맞춤제작 신청하는 것 또한 가능해요!
          </div>
          <button
            class="button is-dark body4-medium margin-top-24"
            style="height:40px;padding:0 24px"
            @click="routerPush('/template_inquiry')"
          >
            내 소스 파일 판매 신청
          </button>
        </div>
      </div>
    </div>
    <div class="margin-top-60">
      <div class="container">
        <div class="h7 main margin-bottom-20">소스 스토어</div>
        <div class="grid-theme">
          <card-product v-for="card in list" :key="`card-${card.id}`" :card="card"></card-product>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardProduct from '../../../component/CardProduct';
export default {
  name: 'Templates',
  components: { CardProduct },
  created() {
    this.getData();
  },
  data() {
    return {
      list: [],
    };
  },
  methods: {
    getData() {
      let params = {
        category: 70,
        fields:
          'id,name,simple_desc,price,img,partner.img,partner.name,category1,category2,preview_url,visit,rate,params',
        page_num: 1,
        page_length: 10,
        ordering: '-created_time',
      };
      this.$axios.get('user/0/mapping/product', { params: params }).then((res) => {
        this.list = res.data.data;
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.banner
  position relative
  background-color $subcolor50
  padding 48px 0
  overflow hidden

.circle
  position absolute
  width 795px
  height 795px
  right 0px
  top -144px
  background #00B2FF
  opacity 0.3
  filter blur(500px)
  z-index 0
.grid-theme
  display grid
  grid-column-gap 36px
  grid-row-gap 48px
  grid-template-columns repeat(3, 1fr)
</style>
