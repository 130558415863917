<template>
  <div>
    <div v-if="device === 'pc'" class="pc-card unselect" @click="clickDetail(card)">
      <div ref="img" class="card-img position-relative" :style="imgField(card.img, 'pc')">
        <div :class="`card-label ${card.theme_version === 'beta' ? 'beta' : 'version'}`">
          {{ card.theme_version }}
        </div>
        <div class="card-exts" v-if="exts.length > 0">
          <img v-for="(ext, idx) in exts" :key="`ext-${idx}`" :src="`/static/icon/ext_${ext}.svg`" />
        </div>

        <div class="card-img-hover" v-if="card.preview_url !== ''">
          <div class="button-preview-wrapper">
            <div @click.stop>
              <a :href="card.preview_url" target="_blank">
                <div class="button-preview unselect">
                  <div style="flex:1">PC</div>
                  <svg-icon icon="u_arrow-up-right" color="black" :width="12" :height="12"></svg-icon>
                </div>
              </a>
            </div>
            <div class="button-preview unselect" @click.stop.prevent="showMobilePreview(card.preview_url)">
              <div style="flex:1">모바일</div>
              <svg-icon icon="u_arrow-up-right" color="black" :width="12" :height="12"></svg-icon>
            </div>
          </div>
          <a
            v-if="card.category2 === 59"
            @click.stop
            :href="`https://platformfy.com/theme_detail?id=${card.id}&trial=true`"
            target="_blank"
            class="free-button"
          >
            <!-- <a
            v-if="card.category2 === 59"
            @click.stop
            :href="`https://pfa.platformfy.com/theme_detail?id=${card.id}&trial=true`"
            target="_blank"
            class="free-button"
          > -->
            무료체험하기
          </a>
          <div v-else class="free-button" @click.stop.prevent="clickTrial">
            무료체험하기
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="subtitle5 main">{{ card.name }}</div>
        <!-- 파트너 -->
        <div class="flex-between">
          <div class="flex-align" style="margin-top:6px" v-if="card.partner">
            <img
              style="width:22px;height:22px"
              class="margin-right-4"
              :src="card.partner.img"
              v-if="card.partner.img"
            />
            <div class="body5 sub3">{{ card.partner.name }}</div>
          </div>
          <div class="flex-center free-tag size-12 weight-500">무료체험</div>
        </div>
        <div class="margin-top-8 flex-between" v-if="!isAddService">
          <div class="flex-align" style="gap:12px">
            <div class="flex-align" style="gap:4px">
              <img src="/static/icon/u_star.svg" class="svg-sub3" style="width:14px" />
              <div class="body6 sub3">{{ card.rate | currencyNum }}</div>
            </div>
            <div class="flex-align" style="gap:4px">
              <img src="/static/icon/u_eye.svg" class="svg-sub3" style="width:14px" />
              <div class="body6 sub3">{{ card.visit | currencyNum }}</div>
            </div>
          </div>
          <!-- 가격 정보 -->
          <div v-if="discountedPrice(card) > 0">
            <!--<div class="body5 sub4 text-line-through" v-if="isDiscount(card)">{{ card.price.price | currency }}</div>-->
            <template v-if="card.price.price_type === 0">
              <div class="flex-align">
                <div class="margin-right-4 primary h8" v-if="card.price.discount_type === 0">
                  {{ discountText(card) }}
                </div>
                <div class="h8">
                  <span class="sub">{{ discountedPrice(card) | currencyNum }}</span>
                  <span class="body4 sub2">원</span>
                  <span class="body4 sub3" v-if="card.price.is_subscription">/월</span>
                  <!--<span class="body4 sub2 margin-left-4" v-if="card.category2!==42">~</span>-->
                </div>
              </div>
            </template>
            <div class="h8 sub" v-else>
              {{ card.price.price_text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="mobile-card">
      <div class="flex-align" @click="clickDetail(card)">
        <div class="card-img position-relative" :style="imgField(card.img, 'mobile')">
          <div :class="`card-label ${card.theme_version === 'beta' ? 'beta' : 'version'}`">
            {{ card.theme_version }}
          </div>
          <div class="card-exts" v-if="exts.length > 0">
            <img v-for="(ext, idx) in exts.slice(0, 5)" :key="`ext-${idx}`" :src="`/static/icon/ext_${ext}.svg`" />
          </div>
        </div>
        <div class="card-content">
          <div class="subtitle7 main">{{ card.name }}</div>
          <!-- 파트너 -->
          <div class="flex-between">
            <div class="flex-align margin-top-4" v-if="card.partner">
              <img
                style="width:16px;height:16px"
                class="margin-right-4"
                :src="card.partner.img"
                v-if="card.partner.img"
              />
              <div class="body6 sub3">{{ card.partner.name }}</div>
            </div>
            <div class="flex-center free-tag size-12 weight-500">무료체험</div>
          </div>
          <!-- 가격 정보 -->
          <div v-if="discountedPrice(card) > 0">
            <div class="body5 sub4 text-line-through" v-if="isDiscount(card)">{{ card.price.price | currency }}</div>
            <div class="flex-align">
              <div class="margin-right-4 primary body4-bold" v-if="card.price.discount_type === 0">
                {{ discountText(card) }}
              </div>
              <div class="body4-bold">
                <span class="sub">{{ discountedPrice(card) | currencyNum }}</span>
                <span class="body6 sub2">원</span>
                <span class="body6 sub3" v-if="card.price.is_subscription">/월</span>
                <span class="body6 sub2 margin-left-4" v-if="card.category2 !== 42">~</span>
              </div>
            </div>
          </div>
          <div class="flex-align" style="gap:4px" v-if="!isAddService">
            <div class="flex-align" style="gap:3px">
              <img src="/static/icon/u_star.svg" class="svg-sub3" style="width:14px" />
              <div class="body7 sub3">{{ card.rate | currencyNum }}</div>
            </div>
            <div class="flex-align" style="gap:3px">
              <img src="/static/icon/u_eye.svg" class="svg-sub3" style="width:14px" />
              <div class="body7 sub3">{{ card.visit | currencyNum }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from './SvgIcon';
export default {
  name: 'CardProduct',
  components: { SvgIcon },
  props: {
    card: {
      type: Object,
    },
    device: {
      type: String,
      default: 'pc',
    },
    isTheme: {
      type: Boolean,
      default: false,
    },
    isAddService: {
      type: Boolean,
      default: false,
    },
    rate: {
      type: String,
      default: '3:4',
    },
  },
  created() {
    if (this.card.preview_url && this.card.preview_url.indexOf('demo') === -1) {
      let key = this.card.preview_url.split('.')[0].replace('https://', '');
      this.card.preview_url += `/home?demo_test=${key}`;
    }
    this.init();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.img) {
        this.imgWidth = this.$refs.img.getBoundingClientRect().width;
      }
    });
  },
  computed: {
    exts() {
      return this.card.params
        ? this.card.params.find((i) => i.name === '파일 종류')
          ? this.card.params.find((i) => i.name === '파일 종류').value.map((i) => i.name.toLowerCase())
          : []
        : [];
    },
  },
  data() {
    return {
      imgWidth: 0,
      order_product: undefined,
    };
  },
  methods: {
    init() {
      this.order_product = {
        product_id: this.card.id,
        quantity: 1,
        discount_price: 0,
        product_price: 0,
        total_price: 0,
        product: this.cloneItem(this.card),
        order_product_option: [],
        required_option_price: 0,
        add_option_price: 0,
      };
    },
    clickTrial() {
      this.$store.commit('setBasket', this.order_product);
      if (!this.isLogin) {
        this.$store.commit('setPrevPath', this.$route.fullPath);
        this.$store.commit('setTrial', true);
        this.toast('로그인 후 진행해주세요.');
        this.$router.push('/signup');
      } else {
        this.routeGa('', this.card.name, '무료체험');
        this.routerPush('/service_survey');
      }
    },
    showMobilePreview(preview_url) {
      window.name = 'preview_parent';
      let popup = window.open(
        '',
        'previewPopup',
        'width=360, height=640, top=300, left=300, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=yes, scrollbar=no'
      );
      popup.location.href = preview_url;
    },
    discountText(product) {
      let text = '';
      if (this.isDiscount(product)) {
        if (product.price.discount_rate > 0 && product.price.discount_type === 0) {
          text = product.price.discount_rate + '%';
        } else if (product.price.discount_price !== 0 && product.price.discount_type === 1) {
          text = product.price.discount_price + '원';
        }
      }
      return text;
    },
    // 할인 여부
    isDiscount(product) {
      if (product.price.is_discount && product.price.discount_start !== null && product.price.discount_end !== null) {
        let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
        return !(currentDate < product.price.discount_start || currentDate > product.price.discount_end);
      } else if (product.price.discount_start !== null && product.price.discount_end === null) {
        let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
        return currentDate >= product.price.discount_start;
      } else if (product.price.discount_start === null && product.price.discount_end !== null) {
        let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
        return currentDate <= product.price.discount_end;
      } else {
        return false;
      }
    },
    // 상품 가격
    discountedPrice(product) {
      let total = 0;
      if (this.isDiscount(product)) {
        if (product.price.discount_rate > 0 && product.price.discount_type === 0) {
          total = product.price.price * (1 - product.price.discount_rate / 100);
        } else if (product.price.discount_price !== 0 && product.price.discount_type === 1) {
          total = product.price.price - product.price.discount_price;
        }
      } else total = product.price.price;
      return total;
    },
    // 상세 페이지 이동
    clickDetail(card) {
      this.$store.commit('setTrial', false);
      this.routeGa('', `상품 카드(${card.name})`, card.name);
      if (this.isTheme) {
        const a = document.createElement('a');
        // a.href = `https://pfa.platformfy.com/theme_detail?id=${card.id}`;
        a.href = `https://platformfy.com/theme_detail?id=${card.id}`;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        a.parentNode.removeChild(a);
      } else if (this.isAddService) {
        this.$router.push(`/additional_service_detail?id=${card.id}`);
      } else {
        const targetRoute = `/template_detail?id=${this.card.id}`;
        if (this.$route.fullPath !== targetRoute) {
          this.$router.push(targetRoute);
          // 스크롤 탑 0
          window.scrollTo(0, 0);
        }
      }
    },
    imgField(img, device) {
      let style = {
        backgroundImage: `url(${this.image(img)})`,
        backgroundSize: 'cover',
      };
      let [w, h] = this.rate.split(':');
      const rate = parseInt(w) / parseInt(h);
      if (device === 'pc') {
        style.backgroundPosition = 'center';
        style.height = `${parseInt(this.imgWidth * rate)}px`;
      } else {
        style.backgroundPosition = 'top';
      }
      return style;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.card-img-hover
  display none
  position absolute
  top 0
  left 0
  background rgba(0,0,0,0.2)
  width 100%
  height 100%
  padding 20px
  border-radius 8px

.button-preview-wrapper
  display flex
  justify-content flex-end
  gap 8px
  height 36px
.button-preview
  background-color white
  width 80px
  height 32px
  border-radius 50px
  padding 6px 10px 6px 14px
  display flex
  align-items center
  justify-content space-between
  color #333333
  font-size 13px
  text-align center

.free-tag
  width 57px
  height 22px
  border-radius 2px
  color #7B61FF
  background-color #EFECFF

.free-button
  display flex
  align-items center
  justify-content center
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)
  padding 6px 20px
  height 48px
  border-radius 8px
  background-color #FF6600
  color white
  font-size 16px
  font-weight 700

.pc-card
  .card-img
    position relative
    width 100%
    border-radius 8px
    background-color $gray4
    border 1px solid $gray3
    .card-exts
      position absolute
      border-radius 6px
      top 8px
      right 8px
      z-index 1
      padding 7px 10px
      background-color $main
      display flex
      align-items center
      gap 12px
      img
        width 24px
        height 24px

  .card-content
    padding-top 10px

.pc
  .card-img:hover
    .card-img-hover
      display grid

.mobile-card
  .card-img
    position relative
    width 140px
    height 105px
    border-radius 8px
    background-color $gray4
    border 1px solid $gray3
    .card-exts
      position absolute
      border-radius 2px
      top 4px
      right 4px
      z-index 1
      padding 2px 4px
      background-color $main
      display flex
      align-items center
      gap 4px
      img
        width 16px
        height 16px

  .card-content
    margin-left 20px
    flex 1

.card-label
  position absolute
  top 8px
  left 8px
  z-index 1
  color white
  font-style italic
  font-size 12px
  font-weight 600
  border-radius 2px
  height 22px
  display flex
  align-items center
  justify-content center
.beta
  background-color #7B61FF
  padding 1px 7px 1px 6px
.version
  background linear-gradient(322deg, #F60 22.51%, #F43B00 100%)
  padding 1px 8px 1px 6px

@media (max-width:1024px)
  .pc
    display block
  .mobile
    display block
</style>
